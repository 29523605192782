
























































































import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class ShopDetail extends Vue {
  public steps = 0; // 订单状态（目前处于第几步）
  public error_steps: number = 0; // 未审核通过的步骤（门店审核，照片审核）
  public id = ""; // 店铺id
  public shop: any = {}; // 店铺详情
  public form_list: MyForm.formList[] = [];

  jumpThis(){
    let _this = this
    this.$api.request({
      url: "common/shop/two-check-shop",
      data: { shop_id: this.id },
      success(res) {
        _this.$toast('恭喜您门店成功开通')
        setTimeout(time=>{
          window.history.go(-1);
        },1000)
      }
    })
  }

  // 判断底部有几个按钮，动态添加底部padding
  detailBottom(val): number {
    let num = 0;
    let value = Number(val);
    if (value === 1 || value === 2 || value === 3 || value === 5) {
      num++;
    }
    if (value > 2) {
      num++;
    }
    return num;
  }
  // 获取门店详情（审核列表）
  getShopDetail() {
    let _this = this;
    this.$api.request({
      url: "common/shop/shop-details",
      data: { shop_id: this.id },
      success(res) {
        _this.shop = res.data;
        _this.setFormList(res.data);
        switch (res.data.shop_status) {
          case "0":
            _this.steps = 0;
            break;
          case "1":
            // 门店审核未通过
            _this.error_steps = 1;
            _this.steps = 1;
            break;
          case "2":
            // 待付款
            _this.steps = 1;
            break;
          case "3":
            // 待发货
            _this.steps = 2;
            break;
          case "4":
            // 待上传照片
            _this.steps = 3;
            break;
          case "5":
            _this.steps = 4;
            break;
          case "6":
            // 照片审核未通过
            _this.error_steps = 4;
            _this.steps = 4;
            break;
          case "7":
            // 照片审核通过
            _this.error_steps = 5;
            _this.steps = 4;
            break;
        }
      }
    });
  }
  // 设置初始化信息
  setFormList(shop: any) {
    this.form_list.forEach((item, index) => {
      if (shop[item.name]) {
        item.value = shop[item.name];
        item.hidden = false;
        return item;
      }
    });
  }
  // 设置提示的问题
  getNoticeText() {
    let steps = this.steps;
    let error_steps = this.error_steps;
    let text = "";
    switch (steps) {
      case 0:
        text = "您的店铺已提交审核";
        break;
      case 1:
        text =
          error_steps === steps
            ? "申请审核失败（" + this.shop.admin_note + "），请店铺信息"
            : "您的订单待付款，请前往付款";
        break;
      case 2:
        text = "您的订单待发货，请耐心等待";
        break;
      case 3:
        text = "您的订单已发货，请耐心等待";
        break;
      case 4:
        text = "您的照片正在审核中";
        if (error_steps) {
          text =
            error_steps === steps
              ? "照片审核失败（" + this.shop.admin_note + "），请重新上传照片"
              : "";
        }
        break;
    }
    if (text && this.shop.shop_id) {
      return text + "，如有问题可联系客服";
    }
    return "";
  }

  init() {
    this.steps = 0;
    this.error_steps = 0;
    this.id = String(this.$route.query.id || "");
    this.shop = {};
    this.form_list = [
      {
        name: "shop_name",
        value: "",
        label: "店铺名称",
        type: "text",
        hidden: true
      },
      {
        name: "shop_time",
        value: "",
        label: "开店时间",
        type: "text",
        hidden: true
      },
      {
        name: "staff_number",
        value: "",
        label: "员工数量",
        type: "text",
        right_text: "人",
        hidden: true
      },
      {
        name: "turnover_money",
        value: "",
        label: "预计年营业额",
        type: "text",
        right_text: "万元/年",
        hidden: true
      },
      {
        name: "region_name",
        value: "",
        label: "门店地址",
        type: "text",
        hidden: true
      },
      {
        name: "address",
        value: "",
        label: "门店详细地址",
        type: "text",
        hidden: true
      },
      {
        name: "acreage",
        value: "",
        label: "经营面积",
        type: "text",
        right_text: "平方米",
        hidden: true
      },
      {
        name: "population",
        value: "",
        label: "区域人口",
        type: "text",
        right_text: "人",
        hidden: true
      },
      // {
      //   name: "manage_type_name",
      //   value: "",
      //   label: "投资方式",
      //   type: "text",
      //   hidden: true
      // },
      {
        name: "teamwork_type",
        value: "",
        label: "合作对象",
        type: "text",
        hidden: true
      },
      {
        name: "relevant_type",
        value: "", // 默认选中
        label: "相关资料",
        type: "text",
        hidden: true
      },
      {
        name: "relevant_note",
        value: "",
        label: "请注明",
        type: "text",
        hidden: true
      },
      {
        name: "door_imr",
        value: "",
        label: "店铺门头",
        type: "image_disabled",
        hidden: true
      },
      {
        name: "business_img",
        value: "",
        label: "营业执照",
        type: "image_disabled",
        hidden: true
      },
      {
        name: "door_decs",
        value: "",
        label: "店内图片",
        type: "image_disabled",
        hidden: true
      },
      {
        name: "site_img",
        value: "",
        label: "蜜印摆放位置",
        type: "image_disabled",
        hidden: true
      }
    ];
    this.$api.refreshForm([this.$refs.form]);

    this.getShopDetail();
  }
}
